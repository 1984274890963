import { graphql } from 'gatsby'
import Markdown from 'markdown-to-jsx'
import React from 'react'

import type { LegacyPageBySlugQuery } from '../../graphql-types'
import BoringContent from '../components/BoringContent'
import Page from '../components/layout/Page'
import Container from '../components/modules/Container'
import Hero from '../components/modules/Hero'

export const query = graphql`
  query LegacyPageBySlug($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }, node_locale: { eq: "en" }) {
      ...LegacyPage
    }
  }
`

interface Props {
  data: LegacyPageBySlugQuery
}

const LegacyPage = ({ data }: Props) => {
  const page = data?.page

  return (
    <Page
      head={{
        title: page?.title,
      }}
    >
      <Container>
        <Hero title={page?.title} />
        <BoringContent>
          <Markdown>{page?.body?.body || ''}</Markdown>
        </BoringContent>
      </Container>
    </Page>
  )
}

export default LegacyPage
