import styled, { css } from 'styled-components'

import { mobile } from './mixins'
import {
  MOBILE_MARGIN,
  WIDTH_PERCENT,
  BODY_INNER_WIDTH,
  MARGIN_HEIGHT,
  TABLET_WIDTH,
  Color,
} from './theme'
import {
  heading1Style,
  heading2Style,
  heading3Style,
  captionRegularStyle,
} from './typography'

const BoringContent = styled.div`
  width: ${WIDTH_PERCENT}%;
  max-width: ${BODY_INNER_WIDTH}px;
  margin: 120px auto 60px;

  line-height: 1.6em;

  h1 {
    ${heading1Style};
    margin-bottom: ${MARGIN_HEIGHT}px;
  }

  h2 {
    ${heading2Style};
  }

  h3 {
    ${heading3Style};
  }

  h2,
  h3 {
    margin: 48px 0 24px;
  }

  p {
    ${captionRegularStyle};
    margin-top: 1em;
  }

  table {
    width: 100%;
    min-width: ${TABLET_WIDTH}px;
    ${captionRegularStyle};
    margin: 2em auto;
    table-layout: fixed;

    th,
    td {
      padding: 1em;
      border: 1px solid ${Color.BORDER};
    }

    td {
      line-height: 1.4em;
      vertical-align: top;
    }
  }
  ul li {
    margin-top: 0;
    margin-left: 1em;
    line-height: 1.6em;
    list-style-type: disc;
  }

  ${mobile(css`
    width: 100%;
    margin: 64px auto;
    padding: 0 ${MOBILE_MARGIN}px;

    header {
      margin: 0 !important;
    }
  `)}
`

export default BoringContent
